import { HTTPV1DOT2 } from "@/axios.js"
HTTPV1DOT2.defaults.timeout = 600000

export default {
    async getEventUsingPincode(pincode) {
        const response = await HTTPV1DOT2.get(`events/pincodes/${pincode}`)
        return response["data"]
    },
    async getStudentProfile(email) {
        const response = await HTTPV1DOT2.get(`students/${email}/profile`)
        console.log("response ", response)
        return response["data"]
    },
    async saveStudentProfile(email, data) {
        const response = await HTTPV1DOT2.put(`students/${email}/profile`, data, { timeout: 600000 })
        return response["data"]
    },
    async getStudentLocation(email, eventid) {
        const response = await HTTPV1DOT2.get(`students/${email}/events/${eventid}/location`)
        return response["data"]
    },
    async saveStudentLocation(email, eventid, lati, long) {
        let rawJsonData = JSON.parse("{}")
        rawJsonData["latitude"] = lati
        rawJsonData["longitude"] = long
        const response = await HTTPV1DOT2.post(`students/${email}/events/${eventid}/location`, rawJsonData, { timeout: 600000 })
        return response["data"]
    },
    async getEventOfUser(email, eventid) {
        let response = await HTTPV1DOT2.get(`students/${email}/events/${eventid}`)
        let eventData = await this.getEventById(eventid)
        let returnData = JSON.parse("{}")
        returnData["eventData"] = eventData
        returnData["studentEvent"] = response["data"]["data"]
        return returnData
    },
    async getEventListAll() {
        let response = await HTTPV1DOT2.get(`events/eventall`)
        return response
    },
    async getEventListByOwner(email) {
        let response = await HTTPV1DOT2.get(`events/info?owner=${email}`)
            .then((res) => {
                if (res.data) {
                    // console.log("getCourseByOwner(API Service):",res.data);
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentStatus(eventId) {
        let response = await HTTPV1DOT2.get(`lms/quiz/status?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    console.log("studentStatus(API)", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getEventById(eventId) {
        let response = await HTTPV1DOT2.get(`events/${eventId}/info`)
        if (response["data"]["data"]) {
            return response["data"]["data"]
        } else {
            return false
        }
    },
    async getStudentReport(eventId) {
        let response = await HTTPV1DOT2.get(`event/leave/report?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    console.log("studentReport(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentReportEachUser(eventId, email) {
        let response = await HTTPV1DOT2.get(`event/leave/user?eventid=${eventId}&email=${email}`)
            .then((res) => {
                if (res.data) {
                    console.log("getStudentReportEachUser(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getSection(courseCode, courseSection, term) {
        let response = await HTTPV1DOT2.get(`reg/detailofcourse?courseid=${courseCode}&semester=${term}`)
            .then((res) => {
                if (res.data) {
                    console.log("getSection(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getQuiz(examId) {
        let response = await HTTPV1DOT2.get(`lms/quizzes/list?courseid=${examId}`)
            .then((res) => {
                if (res.data) {
                    console.log("getQuiz(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getAssignment(examId) {
        let response = await HTTPV1DOT2.get(`lms/assignment/list?courseid=${examId}`)
            .then((res) => {
                if (res.data) {
                    console.log("getAssignment(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createEvent(data) {
        console.log(data)
        var response = await HTTPV1DOT2.post(`event/create`, data, { timeout: 600000 })
            .then((res) => {
                if (res.data) {
                    console.log("createEvent(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteEvent(eventId) {
        console.log(eventId)
        var response = await HTTPV1DOT2.delete(`delete/event?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    console.log("deleteEvent(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getEventAvailableById(eventId) {
        let response = await HTTPV1DOT2.get(`event/available/byeventid?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    console.log("getEventAvailableById(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async createEventAvailable(eventId) {
        console.log(eventId)
        var response = await HTTPV1DOT2.post(`event/available/create`, {
                eventid: eventId,
            })
            .then((res) => {
                if (res.data) {
                    console.log("createEventAvailable(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async deleteEventAvailable(eventId) {
        console.log(eventId)
        var response = await HTTPV1DOT2.delete(`event/available/delete?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    console.log("deleteEventAvailable(API):", res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getStudentPhoto(studentId) {
        let response = await HTTPV1DOT2.get(`mis/photo/student?studentid=${studentId}`, {
                responseType: "arraybuffer",
            })
            .then((res) => {
                res.data = new Buffer(res.data, "binary").toString("base64")
                if (res.data) {
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async checkUserCheckInStatus(eventid, email) {
        let response = await HTTPV1DOT2.get(`events/${eventid}/students/${email}/status`)
        console.log(`response-:`, response['data']['data'])
            // let studentData = response["data"]["data"]
            // if (studentData["state_checkin"] && studentData["state_finished"]) {
            // } else {
            //     return false
            // }
        return true
    },
    async checkExamStateOfuser(eventid, email) {
        let response = await HTTPV1DOT2.get(`manual/events/${eventid}/email/${email}/quiz`)
        let studentData = response["data"]["data"]
        return studentData
    },
    async userCheckIn(email, eventid) {
        try {
            let response = await HTTPV1DOT2.put(`students/${email}/events/${eventid}/checkin`)
            return response["data"]["data"]
        } catch (error) {
            return false
        }
    },
    async userCheckOut(email, eventid) {
        try {
            let response = await HTTPV1DOT2.put(`students/${email}/events/${eventid}/checkout`)
            return response["data"]["data"]
        } catch (error) {
            return false
        }
    },
    async userReportCheckIn(reportId, email) {
        let response = await HTTPV1DOT2.put(`report/student/checkin?reportid=${reportId}&email=${email}`)
            .then((res) => {
                if (res.data) {
                    console.log(res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async userReportCheckOut(reportId, email) {
        let response = await HTTPV1DOT2.put(`report/student/checkout?reportid=${reportId}&email=${email}`)
            .then((res) => {
                if (res.data) {
                    console.log(res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
    async getReportWithEventId(eventId) {
        let response = await HTTPV1DOT2.get(`report/of/event?eventid=${eventId}`)
            .then((res) => {
                if (res.data) {
                    // console.log("getUserRole(API):",res.data)
                    return res.data
                }
            })
            .catch((e) => {
                console.log(e)
            })
        return response
    },
}